var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',[(_vm.institution && _vm.institution.internal_use_id == 'ciisa_uss')?[(_vm.allows_crud)?_c('button-add',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Agregar ${_vm.$getVisibleNames(
              'teaching.tempcompetenceunit',
              false,
              'Unidad de competencia'
            )}`
          ),expression:"\n            `Agregar ${$getVisibleNames(\n              'teaching.tempcompetenceunit',\n              false,\n              'Unidad de competencia'\n            )}`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.$bvModal.show(`add-unit-competence-modal-${_vm.matter_id}`)}}}):_vm._e(),_c('b-modal',{attrs:{"id":`add-unit-competence-modal-${_vm.matter_id}`,"hide-footer":"","title":`Agregar ${_vm.$getVisibleNames(
            'teaching.tempcompetenceunit',
            false,
            'Unidad de competencia'
          )}`,"size":"lg","no-enforce-focus":""}},[_c('CompetenceUnitForm',{attrs:{"matter_id":_vm.matter_id},on:{"created":function($event){return _vm.$bvModal.hide(`add-unit-competence-modal-${_vm.matter_id}`)},"close_modal":function($event){return _vm.$bvModal.hide(`add-unit-competence-modal-${_vm.matter_id}`)}}})],1)]:_vm._e()],2),_vm._l((_vm.competenceUnits),function(competence_unit,index){return _c('div',{key:'CompetenceUnitCell' + competence_unit.id + '-' + _vm.matter_id,class:{ competence_cell_block: index + 1 != _vm.competenceUnits.length }},[_c('div',[_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(competence_unit.order)+". "),_c('SentenceContainer',{attrs:{"Sentence":competence_unit}})],1),_c('div',[(_vm.allows_crud)?_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive.top",value:(
              `Editar ${_vm.$getVisibleNames(
                'teaching.tempcompetenceunit',
                false,
                'Unidad de competencia'
              )}`
            ),expression:"\n              `Editar ${$getVisibleNames(\n                'teaching.tempcompetenceunit',\n                false,\n                'Unidad de competencia'\n              )}`\n            ",modifiers:{"v-secondary":true,"noninteractive":true,"top":true}}],on:{"click":function($event){return _vm.$bvModal.show(
                `edit-unit-competence-modal-${competence_unit.id}-${_vm.matter_id}`
              )}}}):_vm._e(),_c('b-modal',{attrs:{"id":`edit-unit-competence-modal-${competence_unit.id}-${_vm.matter_id}`,"hide-footer":"","title":`Editar ${_vm.$getVisibleNames(
              'teaching.tempcompetenceunit',
              false,
              'Unidad de competencia'
            )}`,"size":"lg"}},[_c('CompetenceUnitForm',{attrs:{"matter_id":_vm.matter_id,"CompetenceUnit":competence_unit,"show_delete_button":true},on:{"created":function($event){return _vm.$bvModal.hide(
                  `edit-unit-competence-modal-${competence_unit.id}-${_vm.matter_id}`
                )},"updated":function($event){return _vm.$bvModal.hide(
                  `edit-unit-competence-modal-${competence_unit.id}-${_vm.matter_id}`
                )}}})],1)],1)])])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }